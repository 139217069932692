.twdLogininermain{position: relative;}
.twoDlohbgimbox{width: 100%;height: 100vh;}
.twoDlohbgimbox img{height: 100%;width: 100%;}
.tddWhitlogobox{position: absolute;top: 40px;left: 40px;}
.twoddLogindemain{padding: 40px 40px;width: 450px;border-radius: 2px;background: #fff;position: absolute;top: 202px;right: 114px;}
.twoDformitm{margin-bottom: 26px; position: relative;transition: 0.3s;}
.twoDformitm input{transition: 0.3s;z-index: 2;position: relative;}
.error .twoDcontol {border: 0.5px solid #ff9f9f}
.error .errorText,.errorText{position: absolute;bottom: -14px;left: 0;font-size: 10px;color: red;font-style: italic;line-height: initial;}
.twoDcontol{padding: 8px 16px;display:block;width:100%;height:50px;font-size:12px;color:#222;background:#fff;border: none; border:0.5px solid #cbcbcb;box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none;border-radius: 2px;}
.twoDfolable{color: #666;font-size: 14px;position: absolute;left: 16px;top: 16px;background-color: #fff;transition: 0.3s;z-index: 0;}
.twoDformitm input:focus + .twoDfolable,.twoDformitm input:valid + .twoDfolable{padding: 0px 7px;top: -10px;left:12px;font-size: 12px;transition: 0.3s;color: #222;z-index: 2;}
.dispBlok{display: block;}
.psswordShowbox{position: absolute;top: 0px;right: 0px;cursor: pointer;height: 100%;
    width: 10%;
    display: flex;
    padding: 8px;z-index: 9;}
.twDbuttprbox{display: flex;justify-content: center;}
.twDbuttprbox button{padding: 15px 120px;background: #17a8e5;color: #fff;border-radius: 2px;font-size: 16px;width: 100%;cursor: pointer;}
.coprighybox{display: flex;align-items: center;width: 100%;justify-content: center;}
.apiError{color: red; margin-bottom: 10px;}